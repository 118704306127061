<template>
  <div>
    <transition name="dashboard">
      <div class="main-div">
        <!-- <h1>Data Peserta</h1> -->

        <div class="search">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-autocomplete
                :loading="loadingCompany"
                v-if="isAdmin"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                placeholder="BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>
              <v-autocomplete
                :loading="loadingBranch"
                :items="filter.branch"
                v-if="isAdmin || isHeadquarters"
                v-model="filter.selectedBranch"
                class="px-5"
                label="Cabang BMT"
                placeholder="Cabang"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>

              <v-autocomplete
                :items="filter.list"
                v-model="filter.selected"
                label="Filter Periode"
                class="px-5"
                clearable
                item-text="name"
                item-value="value"
                single-line
                @change="getAllSubscription()"
              ></v-autocomplete>
              <v-combobox
                :items="filter.category"
                v-model="filter.selectedCategory"
                class="px-5"
                label="Kategori"
                clearable
                item-text="name"
                item-value="value"
                chips
                multiple
              ></v-combobox>
            </v-card>
          </v-menu>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="isExcelLoading"
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                small
                fab
                color="primary"
              >
                <v-icon @click="generateReport">
                  mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </template>
            <span>Export to Excel</span>
          </v-tooltip>
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllSubscription()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="subscriptions"
          :loading="loading"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          item-key="id"
          hide-default-footer
          height="600px"
          class="datatable pt-5"
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-subheader>{{ item.memberName }}</v-subheader>
                <v-list-item @click="onClickFooter(0, item.id, item.id_company)">
                  <v-list-item-icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Rincian data Iuran</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadDocument(item.claimDocument)">
                  <v-list-item-icon>
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Unduh Dokumen</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin" @click="returnSubscription(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-account-reactivate-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Batal Lunas</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div :class="[isAdmin ? 'footer-admin' : 'footer-bmt']">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllSubscription()"
            />
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
    </transition>
    <v-dialog persistent scrollable v-model="dialog" max-width="1200">
      <subscription
        :id_subscription="selectedSubscription.id_subscription"
        :id_company="selectedSubscription.id_company"
        v-if="selectedForm == 0"
        @change-form="changeForm"
        @close-form="closeForm"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

import Subscription from '../components/subscription/SubsInput'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')
const { jsPDF } = require('jspdf')

export default {
  components: { Subscription },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      loadingHeader: true,
      loadingCompany: false,
      loadingBranch: false,
      isExcelLoading: false,
      isCertificateLoading: false,
      isAdmin: false,
      subscriptions: [],
      subscriptionNewStatus: false,
      selectedSubscription: '',
      isHeadquarters: false,
      dialog: '',
      itemDialog: false,
      menu: '',
      filter: {
        selected: '',
        selectedCompany: '',
        selectedBranch: '',
        selectedCategory: [],
        subscription: 0,
        total: 0,
        category: [
          { name: 'Klaim', value: 'claim' },
          { name: 'Lunas', value: 'paid' },
          { name: 'Jatuh Tempo', value: 'due' }
        ],
        list: [],
        company: [],
        branch: []
      },
      header: '',
      selectedForm: null,
      pages: 0,
      formList: [
        { name: 'Subscription', width: 1200 },
        { name: 'Claim', width: 1000 },
        { name: 'Repayment', width: 700 }
      ],
      table: {
        singleExpand: true,
        expanded: [],
        search: '',
        headers: [
          { text: 'Peserta', value: 'memberName', width: '100px', sortable: false },
          { text: 'Produk', value: 'productCode', width: '80px', sortable: false },
          { text: 'Tarif Iuran', value: 'percent', width: '100px', sortable: false },
          { text: 'Jumlah Iuran', value: 'nominal', width: '100px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      }
    }
  },

  watch: {
    'table.search'(val) {
      this.table.pagination.page = 1
      this.searchData(this)
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'filter.selectedCompany'(value) {
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
      this.getBranchCompany(value)
    },
    'filter.selectedBranch'(value) {
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
    },
    'filter.selectedCategory': {
      handler(val) {
        this.getAllSubscription()
      },
      deep: true
    }
  },
  created() {
    this.$socket.client.off('subcription')
    this.$socket.client.off('claim')
    this.$socket.client.off('payment')

    this.$socket.client.on('subcription', data => {
      this.getAllSubscription(true)
    })
    this.$socket.client.on('claim', data => {
      this.getAllSubscription(true)
    })
    this.$socket.client.on('payment', data => {
      this.getAllSubscription(true)
    })
  },

  mounted() {
    this.$nextTick(() => {
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
        this.isAdmin = true
        this.table.headers.unshift({ text: 'BMT', value: 'companyName', width: '150px' })
      } else if (this.$store.getters.userLoggedIn.company.isHeadquarters) {
        this.isHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
        this.getBranchCompany(this.$store.getters.userLoggedIn.company.id)

        this.table.headers.unshift({ text: 'Cabang', value: 'branch', width: '150px' })
      }

      this.filter.list = this.monthFilter()
      this.filter.selected = this.filter.list[0].value
      this.getAllCompany()

      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
    })
  },
  methods: {
    searchData: _.debounce(v => {
      v.getAllSubscription()
    }, 650),
    getHeader() {
      this.loadingHeader = true
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/subscription/total/${this.$store.getters.userLoggedIn.company.id}?periode=${this.filter.selected}`
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data } = response.data
              this.header = data
            }
            this.loadingHeader = false
          })
      })
    },
    getAllCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
          if (response.status == 200) {
            const companyDataGet = response.data.data
            this.filter.company = companyDataGet
              .filter(v => v.name !== 'ADMINISTRATOR')
              .map(company => {
                return {
                  value: _.get(company, '_id', '').toString(),
                  text: _.get(company, 'name', '')
                }
              })
          }
          this.loadingCompany = false
        })
      })
    },
    getBranchCompany(id_company) {
      this.loadingBranch = true
      this.runApiCalls(() => {
        if (id_company) {
          axios.get(Constant.apiUrl.concat(`/master/company/branch/${id_company}`)).then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.filter.branch = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR')
                .map(company => {
                  return {
                    value: _.get(company, '_id', '').toString(),
                    text: _.get(company, 'headquarters.description', '')
                  }
                })

              if (this.isAdmin) {
                this.filter.branch.unshift({ value: this.filter.selectedCompany, text: 'PUSAT' })
                this.filter.branch.unshift({ value: '', text: 'ALL' })
              } else {
                this.filter.branch.unshift({
                  value: this.$store.getters.userLoggedIn.company.id,
                  text: 'PUSAT'
                })
                this.filter.selectedBranch = this.$store.getters.userLoggedIn.company.id
              }
            }
            this.loadingBranch = false
          })
        } else {
          this.loadingBranch = false
          this.filter.selectedBranch = ''
          this.filter.branch = []
        }
      })
    },

    getAllSubscription(isSocketTrigger = false) {
      this.loading = !isSocketTrigger ? true : false
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany
      if (this.filter.selectedBranch)
        company =
          this.filter.selectedBranch == 'Pusat'
            ? this.$store.getters.userLoggedIn.company.id
            : this.filter.selectedBranch

      let isHq = ''
      if (
        (this.isHeadquarters || this.isAdmin) &&
        !this.filter.selectedBranch &&
        this.filter.selectedBranch != this.filter.selectedCompany
      )
        isHq = '1'
      // debugger
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/subscription?page=',
              this.table.pagination.page,
              '&itemCount=',
              this.table.pagination.itemsPerPage,
              '&sort=',
              'timestamps.created_at',
              '&search=',
              this.table.search,
              '&company=',
              company || '',
              '&periode=',
              this.filter.selected || '',
              '&headquarters=',
              isHq,
              '&history=1',
              '&category=',
              this.filter.selectedCategory.map(c => c.value)
            )
          )
          .then(response => {
            // debugger
            if (response.status == 200) {
              const { data, length } = response.data
              this.pages = 0
              this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
              this.subscriptions = []
              this.subscriptions = data.map(subscription => {
                return {
                  id: subscription?._id.toString(),
                  subscriptionNumber: subscription?.subscription_number,
                  id_company: subscription?.id_company?._id,
                  companyName: subscription?.id_company?.name,
                  memberName: subscription?.id_member?.name,
                  productName: subscription?.id_product?.name,
                  productCode: subscription?.id_product?.code,
                  branch: subscription?.id_company?.isHeadquarters
                    ? 'Pusat'
                    : subscription?.id_company.headquarters.description,
                  plafond: this.getCurrency(subscription?.plafond),
                  periode: subscription?.periode || 0,
                  claim: this.getCurrency(subscription?.claim || 0),
                  percent: subscription?.tariff?.percent.toString().concat(' %'),
                  nominal: this.getCurrency(subscription?.tariff?.nominal || 0),
                  disbursement: moment(subscription?.disbursement)
                    .locale('id')
                    .format('D MMMM YYYY'),
                  paidStatus: subscription?.paid_status?.status,
                  paidDescription: subscription?.paid_status?.description,
                  claimStatus: subscription?.claim_status?.status,
                  claimDate: moment(subscription?.claim_status?.created_at)
                    .locale('id')
                    .format('D MMMM YYYY'),
                  paidDate: moment(subscription?.paid_status?.created_at)
                    .locale('id')
                    .format('D MMMM YYYY'),
                  createdAt: subscription?.timestamps?.created_at,
                  claimDocument: subscription?.claim_document
                }
              })
              this.loading = false
            } else {
              this.pages = 0
              this.subscriptions = []
              this.loading = false
            }
          })
      })
    },
    getAllSubscriptionHeader() {
      this.loadingHeader = true
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany
      if (this.filter.selectedBranch)
        company =
          this.filter.selectedBranch == 'Pusat'
            ? this.$store.getters.userLoggedIn.company.id
            : this.filter.selectedBranch

      let isHq = ''
      if (
        (this.isHeadquarters || this.isAdmin) &&
        !this.filter.selectedBranch &&
        this.filter.selectedBranch != this.filter.selectedCompany
      )
        isHq = '1'

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/subscription?company=',
              company || '',
              '&sort=',
              'timestamps.created_at',
              '&filter=',
              this.filter.selected || '',
              '&headquarters=',
              isHq
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data

              this.filter.subscription = data.length
              this.filter.total = this.getCurrency(_.sum(data.map(m => _.get(m, 'tariff.nominal', 0))))

              this.loadingHeader = false
            } else {
              this.filter.subscription = 0
              this.filter.total = this.getCurrency(0)
              this.loadingHeader = false
            }
          })
      })
    },
    returnSubscription(item) {
      try {
        Swal.fire({
          title: 'Batal Pelunasan?',
          text: `Pembatalan pelunasan akan mengembalikan anggota pada status terakhir sebelum di lunaskan`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Kembali'
        }).then(result => {
          if (result.isConfirmed) {
            this.runApiCalls(() => {
              axios
                .put(Constant.apiUrl.concat('/subscription/return/', item.id))
                .then(response => {
                  if (response.status === 200) {
                    this.subscriptions = []
                    this.getAllSubscription(false)
                  }
                  Constant.swalToast('sucess', 'Batal lunas berhasil')
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
            })
          }
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    deleteSubscription(item) {
      try {
        Swal.fire({
          title: 'Anda yakin akan membatalkan ?',
          text: `Data tidak bisa dikembalikan`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        })
          .then(result => {
            if (result.value) {
              this.runApiCalls(() => {
                axios
                  .post(Constant.apiUrl.concat('/subscription/delete/', item.id))
                  .then(() => {
                    this.$socket.client.emit('claim:update', {
                      company: this.$store.getters.userLoggedIn.company.name,
                      staff: this.$store.getters.userLoggedIn
                    })
                    Swal.fire('Berhasil!', 'Peserta berhasil dibatalkan', 'success')
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: message
              })
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menyambung ke server'
              })
            } else {
              // eslint-disable-next-line
              console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    claimSubscription(item, claimStatus) {
      if (item.claimStatus == '') {
        try {
          Swal.fire({
            title: 'Anda Yakin ?',
            text: `Pengajuan santunan untuk ${item.memberName}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then(result => {
            if (result.value) {
              this.runApiCalls(() => {
                axios
                  .put(
                    Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company),
                    {
                      claim: {
                        periode: {
                          month: moment()
                            .locale('id')
                            .format('MMMM'),
                          year: moment()
                            .locale('id')
                            .format('YYYY')
                        },
                        status: claimStatus,
                        description: ''
                      },
                      staff: this.$store.getters.userLoggedIn.id
                    }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$socket.client.emit('claim:update', {
                        company: this.$store.getters.userLoggedIn.company.name,
                        staff: this.$store.getters.userLoggedIn
                      })
                      this.getAllSubscription(false)
                      this.onClickFooter(1, item.id, item.id_company, true)
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat menyambung ke server'
          })
        }
      } else this.onClickFooter(1, item.id, item.id_company, true)
    },
    downloadCertificate(data) {
      try {
        this.isCertificateLoading = true
        this.runApiCalls(() => {
          axios
            .post(Constant.apiUrl.concat('/subscription/certificate'), {
              subscription: data.id,
              company: data.companyName,
              member: data.memberName,
              staff: this.$store.getters.userLoggedIn.name
            })
            .then(response => {
              if (response.status == 200) {
                const fileLink = document.createElement('a')
                fileLink.href = Constant.apiUrl.concat(
                  `/subscription/certificate?`,
                  `company=${data.companyName}`,
                  `&member=${data.memberName}`,
                  `&subscription=${data.id}`
                )
                fileLink.click()
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: '!200'
                })
              }
              this.isCertificateLoading = false
            })
        })
      } catch (error) {
        this.isCertificateLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    generateReport() {
      try {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'info',
          title: 'Sedang mengunduh laporan mohon ditunggu...',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 5000
        })
        this.isExcelLoading = true
        let isHq = ''
        if (
          (this.isHeadquarters || this.isAdmin) &&
          !this.filter.selectedBranch &&
          this.filter.selectedBranch != this.filter.selectedCompany
        )
          isHq = '1'

        let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
        if (this.filter.selectedCompany) company = this.filter.selectedCompany
        if (this.filter.selectedBranch)
          company =
            this.filter.selectedBranch == 'Pusat'
              ? this.$store.getters.userLoggedIn.company.id
              : this.filter.selectedBranch

        this.runApiCalls(() => {
          const fileLink = document.createElement('a')
          fileLink.href = Constant.apiUrl.concat(
            `/reporting/xlsx?`,
            `company=${this.$store.getters.userLoggedIn.company.name}`,
            `&filtercompany=${company}`,
            `&periode=${this.filter.selected}`,
            `&id_company=${!this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''}`,
            `&isheadquarter=${isHq}`,
            '&history=1',
            '&category=',
            this.filter.selectedCategory.map(c => c.value)
          )
          fileLink.click()
          this.isExcelLoading = false
        })
      } catch (error) {
        this.isExcelLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    onClickFooter(index, id_subscription, id_company, isSave) {
      this.selectedSubscription = {
        id_subscription,
        id_company,
        isSave
      }
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllSubscription()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}
.filter-bmt {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  right: 0;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 25%;
}
.autocomplete {
  max-width: 16%;
  margin-right: 2%;
}
.combobox {
  max-width: 30%;
}

.filter {
  position: relative;
  top: 10px;
  left: 5%;
}
.label {
  position: relative;
  margin-right: 10%;
}

.footer-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.footer-bmt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.datatable {
  max-height: 400px;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  &.row-datatable {
    border: none !important;
    &.in-process {
      border-left: 10px solid $primary-color !important;
    }
    &.finished {
      border-left: 10px solid #30cd72 !important;
    }
  }
  .detail-patient {
    display: grid;
    grid-template-columns: 105px minmax(165px, 170px) minmax(200px, 175px);
    grid-template-rows: minmax(15px, 20px) 35px;
    grid-template-areas:
      '. no_reg name no_rm payment poli_type'
      '. no_reg name no_rm payment poli_type';
    border: none !important;
    text-align: left;
    color: #707070;
    &:hover {
      background: none !important;
      & > * {
        background: none !important;
      }
    }
    &.in-process {
      border-left: 5px solid $primary-color !important;
    }
    &.finished {
      border-left: 5px solid #30cd72 !important;
    }
    .poli-type {
      color: black;
    }
  }
  .detail-footer {
    color: #a9b1ba;
    // display: grid;
    grid-template-columns: repeat(5, 100px);
    grid-template-rows: 10px;
    grid-column-gap: 50px;
    // background-color: #f2f3f5;
    // padding: 50px;
    padding-left: 80%;
    justify-content: flex-end;
    // font-size: 1.3rem;
    .item {
      margin-right: 20px;
    }
  }
}
.cardtext {
  margin-left: 30px;
  padding-top: 28px;
  text-align: start;
}

@media only screen and (max-width: 1120px) {
  .detail-patient {
    grid-template-columns:
      125px minmax(152px, 1fr) minmax(100px, 112px) minmax(100px, 105px)
      minmax(160px, 162px) minmax(120px, 125px);
  }
}
</style>
