<template>
  <div>
    <transition name="dashboard">
      <div class="main-div">
        <div class="mb-2">
          <v-col>
            <v-row class="d-flex justify-center">
              <v-card min-height="15vh" min-width="25vw" class="white--text " elevation="2" color="blue">
                <v-card-text class="cardtext white--text">
                  <v-col>
                    <v-row class="d-flex justify-start">
                      <span style="font-size: large;" class="font-weight-bold"
                        >Status Bayar Perode {{ displayPeriode }}</span
                      >
                    </v-row>
                    <v-row v-if="loadingHeader" class="d-flex justify-space-around">
                      <v-col cols="12" class="d-flex justify-center">
                        <v-progress-circular :size="70" color="white" indeterminate></v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="5">
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                        <v-row>
                          <span style="font-size: medium;">Belum Dibayar</span>
                        </v-row>

                        <v-row>
                          <span style="font-size: medium;">Menunggu Konfirmasi</span>
                        </v-row>
                      </v-col>
                      <v-col offset="1" cols="1">
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                        <v-row>
                          <span>:</span>
                        </v-row>

                        <v-row>
                          <span>:</span>
                        </v-row>
                      </v-col>
                      <v-col cols="5">
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                        <v-row>
                          <span style="font-size: medium;">{{ header.not_requested }} Peserta</span>
                        </v-row>

                        <v-row>
                          <span style="font-size: medium;">{{ header.requested }} Peserta</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-row>
          </v-col>
        </div>
        <div class="search">
          <v-btn
            class="mr-5"
            v-if="!isAdmin"
            dark
            color="primary"
            @click="onClickFooter(0, '', '', '', true)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-tooltip text="Tooltip">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class="mr-5"
                v-if="!isAdmin"
                dark
                color="primary"
                @click="paidMultipleSubscription()"
              >
                <v-icon>mdi-cash-multiple</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-autocomplete
                :loading="loadingCompany"
                v-if="isAdmin"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                placeholder="BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>
              <v-autocomplete
                :loading="loadingBranch"
                :items="filter.branch"
                v-if="isAdmin || isHeadquarters"
                v-model="filter.selectedBranch"
                class="px-5"
                label="Cabang BMT"
                placeholder="Cabang"
                item-value="value"
                item-text="text"
                single-line
                :clearable="isAdmin"
              ></v-autocomplete>

              <v-autocomplete
                :items="filter.list"
                v-model="filter.selected"
                class="px-5"
                label="Filter Periode"
                item-text="name"
                item-value="value"
                single-line
                @change="getAllSubscription()"
              ></v-autocomplete>
            </v-card>
          </v-menu>
          <v-select
            :items="filter.paidStatus"
            item-text="text"
            item-value="value"
            v-model="filter.selectedPaidStatus"
            style="max-width: 200px;"
            class="ml-3 mt-5"
            label="Status Pembayaran"
          >
          </v-select>
          <!-- <v-select
            :items="filter.claimStatus"
            item-text="text"
            item-value="value"
            v-model="filter.selectedClaimStatus"
            style="max-width: 150px;"
            class="ml-3 mt-5"
            label="Status Klaim"
          >
          </v-select> -->

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="isExcelLoading"
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                small
                fab
                color="primary"
              >
                <v-icon @click="generateReport">
                  mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </template>
            <span>Export to Excel</span>
          </v-tooltip>
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllSubscription()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="subscriptions"
          :loading="loading"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          item-key="id"
          hide-default-footer
          height="500px"
          class="datatable pt-5"
          dense
        >
          <template v-slot:[`item.paidStatus`]="{ item }">
            <div v-if="item.paidStatus == 'requested'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-timer-outline
                  </v-icon>
                </template>
                <span>Menunggu konfirmasi</span>
              </v-tooltip>
            </div>

            <div v-if="item.paidStatus == 'rejected'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon key="item.paidStatus" v-bind="attrs" v-on="on" color="red">
                    mdi-close-outline
                  </v-icon>
                </template>

                <span>{{ item.paidDescription }}</span>
              </v-tooltip>
            </div>

            <div v-if="item.paidStatus == 'approved'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon key="item.paidStatus" v-bind="attrs" v-on="on" color="green">
                    mdi-check-outline
                  </v-icon>
                </template>

                <span>Sudah dibayar pada <br />{{ item.paidDate }}</span>
              </v-tooltip>
            </div>
            <div v-if="item.paidStatus == ''" :class="item.paidStatus == '' ? 'blink' : ''">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="red">
                    mdi-alert-box-outline
                  </v-icon>
                </template>

                <span>Belum dibayar</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.claimStatus`]="{ item }">
            <div v-if="item.claimStatus == 'requested'">
              <div v-if="item.claim && item.passedDate">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-timer-outline
                    </v-icon>
                  </template>
                  <span>Menunggu konfirmasi</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="red">
                      mdi-timer-outline
                    </v-icon>
                  </template>
                  <span v-if="!item.claim && !item.passedDate"
                    >Lengkapi Tanggal kematian dan Nominal Pengajuan Santunan</span
                  >
                  <span v-if="!item.claim && item.passedDate">Lengkapi Nominal Pengajuan Santunan</span>
                  <span v-if="item.claim && !item.passedDate">Lengkapi Tanggl kematian</span>
                </v-tooltip>
              </div>
            </div>
            <div v-if="item.claimStatus == 'rejected'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="red">
                    mdi-close-outline
                  </v-icon>
                </template>
                <span>{{ item.claimDescription }}</span>
              </v-tooltip>
            </div>
            <div v-if="item.claimStatus == 'approved'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">
                    mdi-check-outline
                  </v-icon>
                </template>
                <span>Sudah diklaim</span>
              </v-tooltip>
            </div>
            <div v-if="item.claimStatus == ''">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">
                    mdi-alert-box-outline
                  </v-icon>
                </template>
                <span>Belum diklaim</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-subheader>{{ item.memberName }}</v-subheader>
                <v-list-item @click="onClickFooter(0, item.id, item.id_company, item.id_member, false)">
                  <v-list-item-icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Rincian data Iuran</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadCertificate(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-file-certificate-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cetak Sertifikat</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="item.paidStatus == '' && (item.claimStatus == '' || item.claimStatus != 'approved')"
                  @click="onClickFooter(0, item.id, item.id_company, item.id_member, true)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ubah data Iuran</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="!isAdmin && ('', 'rejected').includes(item.paidStatus)"
                  @click="paidSubscription(item)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-check-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Bayar Iuran </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="!isAdmin && item.paidStatus != '' && item.claimStatus != 'approved'"
                  @click="claimSubscription(item, 'requested')"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-check-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    item.claimStatus != 'approved' ? 'Pengajuan Santunan' : 'Kelengkapan Santunan'
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!isAdmin && item.paidStatus != '' && item.claimStatus != 'approved'"
                  @click="onClickFooter(2, item.id, item.id_company, item.id_member, true)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Pelunasan
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!isAdmin && item.paidStatus != 'approved' && item.claimStatus != 'approved'"
                  @click="deleteSubscription(item)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-off</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Hapus/Batal Kepesertaan
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="footer">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllSubscription()"
            />
          </div>
          <v-spacer></v-spacer>
          <template>
            <v-dialog
              persistent
              v-model="dialog"
              :max-width="selectedForm !== null ? formList[selectedForm].width : 0"
            >
              <subscription
                :id_subscription="selectedSubscription.id_subscription"
                :id_company="selectedSubscription.id_company"
                :id_member="selectedSubscription.id_member"
                :isSave="selectedSubscription.isSave"
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <subs-claim
                :id_subscription="selectedSubscription.id_subscription"
                :isSave="selectedSubscription.isSave"
                v-if="selectedForm == 1"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <subs-repayment
                :id_subscription="selectedSubscription.id_subscription"
                :isSave="selectedSubscription.isSave"
                v-if="selectedForm == 2"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import Constant from '@/const'
import Subscription from '../components/subscription/SubsInput'
import SubsClaim from '../components/subscription/SubsClaim'
import SubsRepayment from '../components/subscription/SubsRepayment'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')
const { jsPDF } = require('jspdf')

export default {
  components: {
    Subscription,
    SubsClaim,
    SubsRepayment
  },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      loadingHeader: true,
      loadingCompany: false,
      loadingBranch: false,
      isExcelLoading: false,
      isCertificateLoading: false,
      isAdmin: false,
      subscriptions: [],
      subscriptionNewStatus: false,
      selectedSubscription: '',
      isHeadquarters: false,
      dialog: '',
      itemDialog: false,
      menu: false,
      filter: {
        selected: '',
        selectedCompany: '',
        selectedBranch: '',
        selectedPaidStatus: 'all',
        selectedClaimStatus: 'all',
        subscription: 0,
        total: 0,
        list: [],
        company: [],
        branch: [],
        paidStatus: [
          { text: 'Semua', value: 'all' },
          { text: 'Belum Dibayar', value: '' },
          { text: 'Menunggu Konfirmasi', value: 'requested' },
          { text: 'Dibayar', value: 'approved' }
        ],
        claimStatus: [
          { text: 'Semua', value: 'all' },
          { text: 'Belum Diklaim', value: '' },
          { text: 'Menunggu Konfirmasi', value: 'requested' },
          { text: 'Diklaim', value: 'approved' }
        ]
      },
      header: '',
      selectedForm: null,
      pages: 0,
      formList: [
        { name: 'Subscription', width: 1200 },
        { name: 'Claim', width: 1000 },
        { name: 'Repayment', width: 700 }
      ],
      table: {
        singleExpand: true,
        expanded: [],
        search: '',
        headers: [
          { text: 'Peserta', value: 'memberName', width: '100px', sortable: false },
          { text: 'Produk', value: 'productCode', width: '80px', sortable: false },
          { text: 'Tarif Iuran', value: 'percent', width: '100px', sortable: false },
          { text: 'Jumlah Iuran', value: 'nominal', width: '100px', sortable: false },
          { text: 'Bayar', value: 'paidStatus', width: '60px', sortable: false },
          { text: 'Klaim', value: 'claimStatus', width: '60px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      }
    }
  },
  computed: {
    displayPeriode() {
      return moment(this.filter.selected)
        .locale('id')
        .format('MMMM YYYY')
    }
  },
  watch: {
    'table.search'() {
      this.searchData(this)
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'filter.selectedCompany'(value) {
      this.table.pagination.page = 1
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
      this.getBranchCompany(value)
    },
    'filter.selectedBranch'(value) {
      this.table.pagination.page = 1
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
    },
    'filter.selectedPaidStatus'(value) {
      this.table.pagination.page = 1
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
    },
    'filter.selectedClaimStatus'(value) {
      this.table.pagination.page = 1
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
    }
  },
  created() {
    this.$socket.client.on('subcription', data => {
      this.getAllSubscription(true)
    })
    this.$socket.client.on('claim', data => {
      this.getAllSubscription(true)
    })
    this.$socket.client.on('payment', data => {
      this.getAllSubscription(true)
    })
  },

  mounted() {
    this.$nextTick(() => {
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
        this.isAdmin = true
        this.table.headers.unshift({ text: 'BMT', value: 'companyName', width: '150px' })
      } else if (this.$store.getters.userLoggedIn.company.isHeadquarters) {
        this.isHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
        this.getBranchCompany(this.$store.getters.userLoggedIn.company.id)

        this.table.headers.unshift({ text: 'Cabang', value: 'branch', width: '150px' })
      }

      this.filter.list = this.monthFilter()
      this.filter.selected = this.filter.list[0].value
      this.getAllCompany()

      this.getAllSubscription()
    })
  },
  methods: {
    setPageOne() {
      this.table.pagination.page = 1
    },
    searchData: _.debounce(v => {
      v.getAllSubscription()
      this.setPageOne()
    }, 650),

    getAllCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
          if (response.status == 200) {
            const companyDataGet = response.data.data
            this.filter.company = companyDataGet
              .filter(v => v.name !== 'ADMINISTRATOR' && v.isActive)
              .map(company => {
                return {
                  value: _.get(company, '_id', '').toString(),
                  text: _.get(company, 'name', '')
                }
              })
          }
          this.loadingCompany = false
        })
      })
    },
    getBranchCompany(id_company) {
      this.loadingBranch = true
      this.runApiCalls(() => {
        if (id_company) {
          axios.get(Constant.apiUrl.concat(`/master/company/branch/${id_company}`)).then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.filter.branch = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR' && v.isActive)
                .map(company => {
                  return {
                    value: _.get(company, '_id', '').toString(),
                    text: _.get(company, 'headquarters.description', '')
                  }
                })
              if (this.isAdmin) {
                this.filter.branch.unshift({ value: this.filter.selectedCompany, text: 'PUSAT' })
              } else {
                this.filter.branch.unshift({
                  value: this.$store.getters.userLoggedIn.company.id,
                  text: 'PUSAT'
                })
                this.filter.selectedBranch = this.$store.getters.userLoggedIn.company.id
                this.filter.branch.unshift({ value: 'all', text: 'SEMUA CABANG' })
              }
            }
            this.loadingBranch = false
          })
        } else {
          this.loadingBranch = false
          this.filter.selectedBranch = ''
          this.filter.branch = []
        }
      })
    },

    getAllSubscription(isSocketTrigger = false) {
      this.loading = !isSocketTrigger ? true : false
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany
      if (this.filter.selectedBranch)
        company =
          this.filter.selectedBranch == 'Pusat'
            ? this.$store.getters.userLoggedIn.company.id
            : this.filter.selectedBranch

      let isHq = ''
      if (
        (this.isHeadquarters || this.isAdmin) &&
        !this.filter.selectedBranch &&
        this.filter.selectedBranch != this.filter.selectedCompany
      )
        isHq = '1'
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              // '/subscription?page=',
              // this.table.pagination.page,
              // '&itemCount=',
              // this.table.pagination.itemsPerPage,
              // '&sort=',
              // '-timestamps.created_at',
              // '&company=',
              // company || '',
              // '&filter=',
              // this.filter.selected || '',
              // '&headquarters=',
              // this.isHeadquarters ? (this.filter.selectedBranch ? '' : '1') : '',
              // '&history=1'

              '/subscription?page=',
              this.table.pagination.page,
              '&itemCount=',
              this.table.pagination.itemsPerPage,
              '&sort=',
              '-timestamps.created_at',
              '&search=',
              this.table.search,
              '&company=',
              company || '',
              '&periode=',
              this.filter.selected || '',
              '&headquarters=',
              isHq,
              '&paidStatus=',
              this.filter.selectedPaidStatus,
              '&claimStatus=',
              this.filter.selectedClaimStatus
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data

              this.pages = 0
              this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)

              this.subscriptions = []
              this.subscriptions = data.map(subscription => {
                return {
                  id: subscription?._id.toString(),
                  subscriptionNumber: subscription?.subscription_number,
                  id_company: subscription?.id_company?._id,
                  companyName: subscription?.id_company?.name,
                  id_member: subscription?.id_member?._id,
                  memberName: subscription?.id_member?.name,
                  productName: _.get(subscription, 'id_product.name', ''),
                  productCode: _.get(subscription, 'id_product.code', ''),
                  branch: _.get(subscription, 'id_company.isHeadquarters', false)
                    ? 'Pusat'
                    : _.get(subscription, 'id_company.headquarters.description', ''),
                  plafond: this.getCurrency(_.get(subscription, 'plafond', '')),
                  periode: _.get(subscription, 'periode', 0),
                  claim: subscription.claim ? this.getCurrency(_.get(subscription, 'claim', 0)) : 0,
                  passedDate: _.get(subscription, 'passed_date', ''),
                  percent: _.get(subscription, 'tariff.percent', '')
                    .toString()
                    .concat(' %'),
                  nominal: this.getCurrency(_.get(subscription, 'tariff.nominal', 0)),
                  disbursement: moment(_.get(subscription, 'disbursement'))
                    .locale('id')
                    .format('D MMMM YYYY'),
                  paidStatus: _.get(subscription, 'paid_status.status', ''),
                  paidDescription: _.get(subscription, 'paid_status.description', ''),
                  claimStatus: _.get(subscription, 'claim_status.status', ''),
                  claimDescription: _.get(subscription, 'claim_status.description', ''),
                  claimDate: moment(_.get(subscription, 'claim_status.created_at'))
                    .locale('id')
                    .format('D MMMM YYYY'),
                  paidDate: moment(_.get(subscription, 'paid_status.created_at'))
                    .locale('id')
                    .format('D MMMM YYYY'),
                  createdAt: _.get(subscription, 'timestamps.created_at')
                }
              })

              this.loading = false
            } else {
              this.pages = 0
              this.subscriptions = []
              this.loading = false
            }
          })
          .catch(error => {
            this.pages = 0
            this.subscriptions = []
            this.loading = false
          })
          .finally(() => {
            this.getAllSubscriptionHeader()
          })
      })
    },
    getAllSubscriptionHeader() {
      try {
        this.loadingHeader = true
        let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
        if (this.filter.selectedCompany) company = this.filter.selectedCompany
        if (this.filter.selectedBranch)
          company =
            this.filter.selectedBranch == 'Pusat'
              ? this.$store.getters.userLoggedIn.company.id
              : this.filter.selectedBranch

        let isHq = ''
        if (
          (this.isHeadquarters || this.isAdmin) &&
          !this.filter.selectedBranch &&
          this.filter.selectedBranch != this.filter.selectedCompany
        )
          isHq = '1'

        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                '/subscription/in-progress-payment?company=',
                company || '',
                '&periode=',
                this.filter.selected || ''
              )
            )
            .then(response => {
              if (response.status == 200) {
                this.header = response.data.data
              } else {
                this.header = {
                  not_requested: 0,
                  requested: 0
                }
              }
            })
            .finally(() => {
              this.loadingHeader = false
            })
        })
      } catch (error) {
      } finally {
        this.loadingHeader = false
      }
    },

    paidSubscription(item) {
      try {
        if (!this.isHeadquarters)
          Swal.fire({
            title: 'Pembayaran',
            text: 'Silahkan pilih metode pembayaran',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            denyButtonColor: '#34eb58',
            confirmButtonText: 'Pusat',
            denyButtonText: `Cabang`,
            cancelButtonText: 'Batal'
          }).then(response => {
            if (response.isConfirmed || response.isDenied) {
              let isPaidFromBranch = null
              if (response.isConfirmed) isPaidFromBranch = false
              else if (response.isDenied) isPaidFromBranch = true

              Swal.fire({
                title: 'Apakah anda yakin?',
                text: `Pembayaran akan di lakukan oleh ${isPaidFromBranch ? 'Cabang' : 'Pusat'} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal'
              }).then(result => {
                if (result.isConfirmed) {
                  this.swalLoading()
                  this.runApiCalls(() => {
                    axios
                      .put(
                        Constant.apiUrl.concat(
                          '/subscription/status/',
                          item.id,
                          '?company=',
                          item.id_company
                        ),
                        {
                          paid: {
                            periode: {
                              month: moment()
                                .locale('id')
                                .format('MMMM'),
                              year: moment()
                                .locale('id')
                                .format('YYYY')
                            },
                            status: 'requested',
                            description: ''
                          },
                          staff: this.$store.getters.userLoggedIn.id,
                          isPaidFromBranch
                        }
                      )
                      .then(response => {
                        if (response.status === 200) {
                          this.$socket.client.emit('payment:update', {
                            company: this.$store.getters.userLoggedIn.company.name,
                            staff: this.$store.getters.userLoggedIn
                          })
                          this.subscriptions = []
                          this.getAllSubscription(false)
                        }
                        this.swalToast(
                          'success',
                          `Pembayaran berhasil terdaftar ${isPaidFromBranch ? '' : 'di Pusat'}`
                        )
                      })
                      .catch(error => {
                        if (error.response) {
                          const message = error.response.data.pesan
                          Swal.fire({
                            icon: 'error',
                            title: 'Terjadi Kesalahan',
                            text: message
                          })
                        } else if (error.request) {
                          Swal.fire({
                            icon: 'error',
                            title: 'Terjadi Kesalahan',
                            text: 'Tidak dapat menyambung ke server'
                          })
                        } else {
                          // eslint-disable-next-line
                          console.log('Something happened in the middle of request')
                        }
                      })
                  })
                }
              })
            }
          })
        else
          Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Pastikan data sudah sesuai`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal'
          }).then(result => {
            if (result.isConfirmed) {
              this.swalLoading()
              this.runApiCalls(() => {
                axios
                  .put(
                    Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company),
                    {
                      paid: {
                        periode: {
                          month: moment()
                            .locale('id')
                            .format('MMMM'),
                          year: moment()
                            .locale('id')
                            .format('YYYY')
                        },
                        status: 'requested',
                        description: ''
                      },
                      staff: this.$store.getters.userLoggedIn.id,
                      isPaidFromBranch: false
                    }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$socket.client.emit('payment:update', {
                        company: this.$store.getters.userLoggedIn.company.name,
                        staff: this.$store.getters.userLoggedIn
                      })
                      this.subscriptions = []
                      this.getAllSubscription(false)
                    }
                    this.swalToast('success', `Pembayaran berhasil terdaftar`)
                    // Swal.fire('Pembayaran', `Pembayaran berhasil terdaftar `, 'success')
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },

    paidMultipleSubscription() {
      try {
        let body = {
          periode: {
            month: moment()
              .locale('id')
              .format('MMMM'),
            year: moment()
              .locale('id')
              .format('YYYY')
          },
          status: 'requested',
          description: '',
          staff: this.$store.getters.userLoggedIn.id,
          isPaidFromBranch: false
        }

        if (!this.isHeadquarters)
          Swal.fire({
            title: 'Pembayaran',
            text: 'Silahkan pilih metode pembayaran',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            denyButtonColor: '#34eb58',
            confirmButtonText: 'Pusat',
            denyButtonText: `Cabang`,
            cancelButtonText: 'Batal'
          }).then(response => {
            if (response.isConfirmed || response.isDenied) {
              if (response.isDenied) body.isPaidFromBranch = true

              Swal.fire({
                title: 'Apakah anda yakin?',
                text: `Pembayaran akan di lakukan oleh ${body.isPaidFromBranch ? 'Cabang' : 'Pusat'} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal'
              }).then(result => {
                if (result.isConfirmed) {
                  this.swalLoading()
                  this.runApiCalls(() => {
                    axios
                      .post(
                        Constant.apiUrl.concat(
                          '/subscription/paid-all',
                          '?company=',
                          this.$store.getters.userLoggedIn.company.id
                        ),
                        body
                      )
                      .then(response => {
                        if (response.status === 200) {
                          this.$socket.client.emit('payment:update', {
                            company: this.$store.getters.userLoggedIn.company.name,
                            staff: this.$store.getters.userLoggedIn
                          })
                          this.subscriptions = []
                          this.getAllSubscription(false)
                        }
                        this.swalToast(
                          'success',
                          `Pembayaran berhasil terdaftar ${body.isPaidFromBranch ? '' : 'di Pusat'}`
                        )
                      })
                      .catch(error => {
                        if (error.response) {
                          const message = error.response.data.pesan
                          Swal.fire({
                            icon: 'error',
                            title: 'Terjadi Kesalahan',
                            text: message
                          })
                        } else if (error.request) {
                          Swal.fire({
                            icon: 'error',
                            title: 'Terjadi Kesalahan',
                            text: 'Tidak dapat menyambung ke server'
                          })
                        } else {
                          // eslint-disable-next-line
                          console.log('Something happened in the middle of request')
                        }
                      })
                  })
                }
              })
            }
          })
        else
          Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Pastikan data sudah sesuai`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal'
          }).then(result => {
            if (result.isConfirmed) {
              this.swalLoading()
              this.runApiCalls(() => {
                axios
                  .post(
                    Constant.apiUrl.concat(
                      '/subscription/paid-all',
                      '?company=',
                      this.$store.getters.userLoggedIn.company.id
                    ),
                    body
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$socket.client.emit('payment:update', {
                        company: this.$store.getters.userLoggedIn.company.name,
                        staff: this.$store.getters.userLoggedIn
                      })
                      this.subscriptions = []
                      this.getAllSubscription(false)
                    }
                    this.swalToast('success', `Pembayaran berhasil terdaftar`)
                    // Swal.fire('Pembayaran', `Pembayaran berhasil terdaftar `, 'success')
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    deleteSubscription(item) {
      try {
        Swal.fire({
          title: 'Anda yakin akan membatalkan ?',
          text: `Data tidak bisa dikembalikan`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        })
          .then(result => {
            if (result.value) {
              this.swalLoading()
              this.runApiCalls(() => {
                axios
                  .post(Constant.apiUrl.concat('/subscription/delete/', item.id))
                  .then(() => {
                    this.$socket.client.emit('claim:update', {
                      company: this.$store.getters.userLoggedIn.company.name,
                      staff: this.$store.getters.userLoggedIn
                    })
                    // Swal.fire('Berhasil!', 'Peserta berhasil dibatalkan', 'success')
                    this.swalToast('success', 'Peserta berhasil dibatalkan')
                    this.getAllSubscription(false)
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: message
              })
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menyambung ke server'
              })
            } else {
              // eslint-disable-next-line
              console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    claimSubscription(item, claimStatus) {
      if (item.claimStatus != 'approved') {
        try {
          Swal.fire({
            title: 'Anda Yakin ?',
            text: `Pengajuan santunan untuk ${item.memberName}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then(result => {
            if (result.value) {
              this.swalLoading()
              this.runApiCalls(() => {
                axios
                  .put(
                    Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company),
                    {
                      claim: {
                        periode: {
                          month: moment()
                            .locale('id')
                            .format('MMMM'),
                          year: moment()
                            .locale('id')
                            .format('YYYY')
                        },
                        status: claimStatus,
                        description: ''
                      },
                      staff: this.$store.getters.userLoggedIn.id
                    }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$socket.client.emit('claim:update', {
                        company: this.$store.getters.userLoggedIn.company.name,
                        staff: this.$store.getters.userLoggedIn
                      })
                      this.swalToast('success', `Pengajuan santunan berhasil terdaftar`)
                      this.getAllSubscription(false)
                      this.onClickFooter(1, item.id, item.id_company, item.id_member, true)
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat menyambung ke server'
          })
        }
      } else this.onClickFooter(1, item.id, item.id_company, item.id_member, true)
    },
    async downloadCertificate(data) {
      try {
        this.isCertificateLoading = true

        await this.generateCertificate({
          id_subscription: data.id,
          company: data.companyName,
          member: data.memberName,
          staff: this.$store.getters.userLoggedIn.name
        })
        this.isCertificateLoading = false
      } catch (error) {
        this.isCertificateLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    generateReport() {
      try {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'info',
          title: 'Sedang mengunduh laporan mohon ditunggu...',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 5000
        })
        this.isExcelLoading = true
        let isHq = ''
        if (
          (this.isHeadquarters || this.isAdmin) &&
          !this.filter.selectedBranch &&
          this.filter.selectedBranch != this.filter.selectedCompany
        )
          isHq = '1'

        let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
        if (this.filter.selectedCompany) company = this.filter.selectedCompany
        if (this.filter.selectedBranch)
          company =
            this.filter.selectedBranch == 'Pusat'
              ? this.$store.getters.userLoggedIn.company.id
              : this.filter.selectedBranch

        this.runApiCalls(() => {
          const fileLink = document.createElement('a')

          fileLink.href = Constant.apiUrl.concat(
            `/reporting/xlsx?`,
            `company=${this.$store.getters.userLoggedIn.company.name}`,
            `&filtercompany=${company}`,
            `&periode=${this.filter.selected}`,
            `&id_company=${!this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''}`,
            `&isheadquarter=${isHq}`
          )
          fileLink.click()
          this.isExcelLoading = false
        })
      } catch (error) {
        this.isExcelLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    onClickFooter(index, id_subscription, id_company, id_member, isSave) {
      this.selectedSubscription = {
        id_subscription,
        id_company,
        id_member,
        isSave
      }
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllSubscription()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}
.filter-bmt {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  right: 0;
}

.search {
  // display: flex;
  // position: relative;
  // align-items: center;
  // justify-content: flex-end;
  // right: 0;
}

.input {
  max-width: 25%;
}
.autocomplete {
  max-width: 14%;
  margin-right: 2%;
}

.filter {
  position: relative;
  top: 10px;
  left: 5%;
}
.label {
  position: relative;
  margin-right: 10%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.datatable {
  max-height: 400px;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  &.row-datatable {
    border: none !important;
    &.in-process {
      border-left: 10px solid $primary-color !important;
    }
    &.finished {
      border-left: 10px solid #30cd72 !important;
    }
  }
  .detail-patient {
    display: grid;
    grid-template-columns: 105px minmax(165px, 170px) minmax(200px, 175px);
    grid-template-rows: minmax(15px, 20px) 35px;
    grid-template-areas:
      '. no_reg name no_rm payment poli_type'
      '. no_reg name no_rm payment poli_type';
    border: none !important;
    text-align: left;
    color: #707070;
    &:hover {
      background: none !important;
      & > * {
        background: none !important;
      }
    }
    &.in-process {
      border-left: 5px solid $primary-color !important;
    }
    &.finished {
      border-left: 5px solid #30cd72 !important;
    }
    .poli-type {
      color: black;
    }
  }
  .detail-footer {
    color: #a9b1ba;
    // display: grid;
    grid-template-columns: repeat(5, 100px);
    grid-template-rows: 10px;
    grid-column-gap: 50px;
    // background-color: #f2f3f5;
    // padding: 50px;
    padding-left: 80%;
    justify-content: flex-end;
    // font-size: 1.3rem;
    .item {
      margin-right: 20px;
    }
  }
}
.cardtext {
  margin-left: 5px;
  padding-top: 5px;
  text-align: start;
}

@media only screen and (max-width: 1120px) {
  .detail-patient {
    grid-template-columns:
      125px minmax(152px, 1fr) minmax(100px, 112px) minmax(100px, 105px)
      minmax(160px, 162px) minmax(120px, 125px);
  }
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
