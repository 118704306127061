<template>
  <div>
    <!-- <v-progress-linear
      v-if="loading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear> -->
    <transition name="dashboard">
      <div class="main-div">
        <!-- <h1>Data Pengajuan Santunan</h1> -->
        <div class="d-flex justify-space-around">
          <v-card min-height="76" min-width="400" class="white--text " elevation="2" color="blue">
            <v-card-text class="cardtext white--text">
              <v-col cols="12">
                <h1>
                  Santunan : <b>{{ header.count }}</b>
                </h1>
                <br />
                <h1>
                  Total Pengajuan Santunan : <b>{{ header.total }}</b>
                </h1>
              </v-col>
            </v-card-text>
          </v-card>
        </div>
        <div class="search">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-autocomplete
                :loading="loadingCompany"
                v-if="isAdmin"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                placeholder="BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>
              <v-autocomplete
                :loading="loadingBranch"
                :items="filter.branch"
                v-if="isAdmin || isHeadquarters"
                v-model="filter.selectedBranch"
                class="px-5"
                label="Cabang BMT"
                placeholder="Cabang"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllSubscription()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="subscriptions"
          :loading="loading"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          item-key="id"
          hide-default-footer
          class="elevation-0 pt-5"
          height="500px"
          dense
        >
          <template v-slot:[`item.claimStatus`]="{ item }">
            <div v-if="item.claimStatus == 'requested'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-timer-outline
                  </v-icon>
                </template>
                <template>
                  <div align="left">
                    <span>Menunggu Kelengkapan Dokumen:</span><br />
                    <ul v-for="doc in item.claimDocument" v-bind:key="doc.title">
                      <li v-if="doc.isEnable">
                        {{ doc.title }}
                        <v-icon :color="doc.isUploaded ? 'green' : 'error'">
                          {{ doc.isUploaded ? 'mdi-check' : 'mdi-close' }}</v-icon
                        >
                      </li>
                    </ul>
                  </div>
                </template>
              </v-tooltip>
            </div>
            <div v-if="item.claimStatus == 'rejected'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="red">
                    mdi-close-outline
                  </v-icon>
                </template>
                <span>Klaim Ditolak</span>
              </v-tooltip>
            </div>
            <div v-if="item.claimStatus == 'approved'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">
                    mdi-check-outline
                  </v-icon>
                </template>
                <span>Sudah diklaim pada <br />{{ item.claimDate }}</span>
              </v-tooltip>
            </div>
            <div v-if="item.claimStatus == ''">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">
                    mdi-alert-box-outline
                  </v-icon>
                </template>
                <span>Belum diklaim</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-subheader>{{ item.memberName }}</v-subheader>
                <v-list-item @click="onClickFooter(0, item.id, item.id_company)">
                  <v-list-item-icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Rincian data Iuran</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadCertificate(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-file-certificate-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cetak Sertifikat</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadDocument(item.claimDocument)">
                  <v-list-item-icon>
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Unduh Dokumen</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin" @click="claimSubscription(item, 'approved')">
                  <v-list-item-icon>
                    <v-icon>mdi-account-heart-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Kelengkapan Santunan</v-list-item-title>
                </v-list-item>
                <v-list-item @click="cancelClaim(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-account-cancel-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Batalkan Klaim</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="footer">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllSubscription()"
            />
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
    </transition>
    <v-dialog persistent scrollable v-model="dialog" max-width="1200">
      <subscription
        :id_subscription="selectedSubscription.id_subscription"
        :id_company="selectedSubscription.id_company"
        v-if="selectedForm == 0"
        @change-form="changeForm"
        @close-form="closeForm"
      />

      <subs-claim-admin
        :id_subscription="selectedSubscription.id_subscription"
        :id_company="selectedSubscription.id_company"
        :isSave="selectedSubscription.isSave"
        v-if="selectedForm == 1"
        @change-form="changeForm"
        @close-form="closeForm"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import Constant from '@/const'
import Subscription from '../components/subscription/SubsInput'
import SubsClaimAdmin from '../components/subscription/SubsClaimAdmin'

import ConstantMixin from '@/constantMixin'
// import { io } from 'socket.io-client'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')
// const socket = io(process.env.VUE_APP_API_URL)

export default {
  components: {
    Subscription,
    SubsClaimAdmin
  },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      isCertificateLoading: true,
      isLoadingHeader: true,
      isAdmin: false,
      subscriptions: [],
      subscriptionNewStatus: false,
      selectedSubscription: '',
      dialog: '',
      selectedForm: null,
      pages: 0,
      menu: false,
      header: {
        count: 0,
        total: 0
      },
      filter: {
        selectedCompany: '',
        company: []
      },
      table: {
        search: '',
        headers: [
          { text: 'BMT', value: 'companyName', width: '100px', sortable: false },
          { text: 'Peserta', value: 'memberName', width: '100px', sortable: false },
          { text: 'Produk', value: 'productName', width: '150px', sortable: false },
          { text: 'Plafond', value: 'plafond', width: '100px', sortable: false },
          { text: 'Santunan', value: 'claim', width: '100px', sortable: false },
          { text: 'Pengajuan Klaim', value: 'claimDate', width: '100px', sortable: false },
          { text: 'Klaim', value: 'claimStatus', width: '60px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      }
    }
  },
  created() {
    this.$socket.client.off('claim')
    this.$socket.client.on('claim', data => {
      this.getAllSubscription(true)
    })
  },
  watch: {
    // 'table.search'(val) {
    //   this.table.pagination.page = 1
    //   this.searchData(this)
    // },
    // subscriptions: {
    //   handler() {
    //     this.$nextTick(() => {
    //       this.table.pagination.totalItems = this.subscriptions.length
    //       if (this.table.pagination.itemsPerPage == null || this.table.pagination.totalItems == null) {
    //         this.pages = 0
    //       } else {
    //         this.pages = Math.ceil(this.table.pagination.totalItems / this.table.pagination.itemsPerPage)
    //       }
    //     })
    //   },
    //   deep: true
    // },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'filter.selectedCompany'(value) {
      this.table.pagination.page = 1
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
      this.getBranchCompany(value)
    },
    'filter.selectedBranch'(value) {
      this.table.pagination.page = 1
      this.getAllSubscription()
      // this.getAllSubscriptionHeader()
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
        this.isAdmin = true
      }
      this.getAllCompany()
      this.getAllSubscription()
      this.getClaimHeader()
      // this.getAllSubscriptionHeader()
    })
  },
  methods: {
    setPageOne() {
      this.table.pagination.page = 1
    },
    searchData: _.debounce(v => {
      v.getAllSubscription()
      this.setPageOne()
    }, 650),

    getAllCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
          if (response.status == 200) {
            const companyDataGet = response.data.data
            this.filter.company = companyDataGet
              .filter(v => v.name !== 'ADMINISTRATOR')
              .map(company => {
                return {
                  value: _.get(company, '_id', '').toString(),
                  text: _.get(company, 'name', '')
                }
              })
          }
          this.loadingCompany = false
        })
      })
    },
    getBranchCompany(id_company) {
      this.loadingBranch = true
      this.runApiCalls(() => {
        if (id_company) {
          axios.get(Constant.apiUrl.concat(`/master/company/branch/${id_company}`)).then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.filter.branch = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR')
                .map(company => {
                  return {
                    value: _.get(company, '_id', '').toString(),
                    text: _.get(company, 'headquarters.description', '')
                  }
                })

              if (this.isAdmin) {
                this.filter.branch.unshift({ value: this.filter.selectedCompany, text: 'PUSAT' })
                this.filter.branch.unshift({ value: '', text: 'ALL' })
              } else {
                this.filter.branch.unshift({
                  value: this.$store.getters.userLoggedIn.company.id,
                  text: 'PUSAT'
                })
                this.filter.selectedBranch = this.$store.getters.userLoggedIn.company.id
              }
            }
            this.loadingBranch = false
          })
        } else {
          this.loadingBranch = false
          this.filter.selectedBranch = ''
          this.filter.branch = []
        }
      })
    },
    getClaimHeader() {
      this.isLoadingHeader = true
      let company = this.$store.getters.userLoggedIn.company.id

      if (this.isAdmin) company = this.filter.selectedCompany

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/subscription/claim/header?',
              'company=',
              company || '',
              '&claim=1',
              '&search=',
              this.table.search,
              '&headquarters=1'
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data
              this.header = {
                count: data.count,
                total: this.getCurrency(data.total)
              }
            }
            this.isLoadingHeader = false
          })
      })
    },
    getAllSubscription(isSocketTrigger = false) {
      this.loading = !isSocketTrigger ? true : false
      let company = this.$store.getters.userLoggedIn.company.id

      if (this.isAdmin) company = this.filter.selectedCompany

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/subscription?page=',
              this.table.pagination.page,
              '&itemCount=',
              this.table.pagination.itemsPerPage,
              '&sort=',
              '-timestamps.created_at',
              '&company=',
              company || '',
              '&claim=1',
              '&search=',
              this.table.search,
              '&headquarters=1'
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data
              this.pages = 0
              this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
              this.subscriptions = []
              this.subscriptions = data.map(subscription => {
                let templates = [
                  {
                    title: 'Surat Permohonan Santunan',
                    isEnable: true,
                    isUploaded: false
                  },
                  {
                    title: 'Surat Keterangan Kematian',
                    isEnable: true,
                    isUploaded: false
                  },
                  {
                    title: 'Kartu Tanda Penduduk dan Kartu Keluarga',
                    isEnable: true,
                    isUploaded: false
                  },
                  {
                    title: 'Akad Pembiayaan',
                    isEnable: true,
                    isUploaded: false
                  },
                  {
                    title: 'Riwayat Angsuran Pembiayaan',
                    // isEnable: ['102', '202'].indexOf(subscription.product_code) >= 0 ? true : false,
                    isEnable: true,
                    isUploaded: false
                  },
                  {
                    title: 'Bukti Transfer',
                    isEnable: false,
                    isUploaded: false
                  }
                ]
                for (const docs of subscription.claim_document) {
                  const isExist = docs.files.length > 0

                  if (docs.name == 'surat permohonan santunan' && isExist) {
                    templates[0].isUploaded = true
                    templates[0].files = docs.files
                  }

                  if (docs.name == 'surat keterangan kematian' && isExist) {
                    templates[1].isUploaded = true
                    templates[1].files = docs.files
                  }
                  if (docs.name == 'kartu tanda penduduk dan kartu keluarga' && isExist) {
                    templates[2].isUploaded = true
                    templates[2].files = docs.files
                  }
                  if (docs.name == 'akad pembiayaan' && isExist) {
                    templates[3].isUploaded = true
                    templates[3].files = docs.files
                  }
                  if (docs.name == 'riwayat angsuran pembiayaan' && isExist) {
                    templates[4].isUploaded = true
                    templates[4].files = docs.files
                  }
                  if (docs.name == 'bukti transfer' && isExist) {
                    templates[5].files = docs.files
                    templates[5].isEnable = true
                    templates[5].isUploaded = true
                  }
                }
                return {
                  id: subscription?._id.toString(),
                  subscriptionNumber: subscription?.subscription_number,
                  id_company: subscription?.id_company?._id,
                  companyName: subscription?.id_company?.name,
                  memberName: subscription?.id_member?.name,
                  productName: subscription?.id_product?.name,
                  productCode: subscription?.id_product?.code,
                  branch: subscription?.id_company?.isHeadquarters
                    ? 'Pusat'
                    : subscription?.id_company.headquarters.description,
                  plafond: this.getCurrency(subscription?.plafond),
                  periode: subscription?.periode || 0,
                  claim: this.getCurrency(subscription?.claim || 0),
                  percent: subscription?.tariff?.percent.toString().concat(' %'),
                  nominal: this.getCurrency(subscription?.tariff?.nominal || 0),
                  disbursement: moment(subscription?.disbursement)
                    .locale('id')
                    .format('D MMMM YYYY'),
                  paidStatus: subscription?.paid_status?.status,
                  paidDescription: subscription?.paid_status?.description,
                  claimStatus: subscription?.claim_status?.status,
                  claimDate: moment(subscription?.claim_status?.created_at)
                    .locale('id')
                    .format('D MMMM YYYY'),
                  paidDate: moment(subscription?.paid_status?.created_at)
                    .locale('id')
                    .format('D MMMM YYYY'),
                  createdAt: subscription?.timestamps?.created_at,
                  claimDocument: templates
                }
              })
            } else {
              this.pages = 0
              this.subscriptions = []
            }

            this.loading = false
          })
      })
    },
    getAllSubscriptionHeader() {
      this.loadingHeader = true
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/subscription?sort=',
              '-timestamps.created_at',
              '&company=',
              company || '',
              '&claim=1'
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data

              if (this.filter.company.length == 0)
                this.filter.company = _.uniq(
                  data.map(m => {
                    return { text: m.id_company.name, value: m.id_company._id }
                  })
                )
            }

            this.loadingHeader = false
          })
      })
    },
    async paidSubscription(item) {
      try {
        await this.runApiCalls(() => {
          axios
            .put(Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company), {
              paid: {
                periode: {
                  month: moment()
                    .locale('id')
                    .format('MMMM'),
                  year: moment()
                    .locale('id')
                    .format('YYYY')
                },
                status: 'requested',
                description: ''
              },
              staff: this.$store.getters.userLoggedIn.id
            })
            .then(response => {
              if (response.status === 200) {
                // Swal.fire('Berhasil', 'Memasukkan Iuran ke list Pembayaran', 'success')
                this.getAllSubscription(false)
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    cancelClaim(item) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: `Membatalkan santunan untuk ${item.memberName}`,
        icon: 'warning',
        input: 'text',
        inputLabel: 'Alasan Pembatalan',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.isConfirmed) {
          this.runApiCalls(() => {
            axios
              .put(Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company), {
                claim: {
                  periode: {
                    month: moment()
                      .locale('id')
                      .format('MMMM'),
                    year: moment()
                      .locale('id')
                      .format('YYYY')
                  },
                  status: 'rejected',
                  description: result.value
                },
                staff: this.$store.getters.userLoggedIn.id
              })
              .then(response => {
                if (response.status === 200) {
                  this.getAllSubscription(false)
                  // this.onClickFooter(1, item.id, item.id_company, true)
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
          })
        }
      })
    },
    claimSubscription(item, claimStatus) {
      if (item.claimStatus == '') {
        try {
          Swal.fire({
            title: 'Anda Yakin ?',
            text: `Konfirmasi santunan untuk ${item.memberName}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then(result => {
            if (result.value) {
              this.runApiCalls(() => {
                axios
                  .put(
                    Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company),
                    {
                      claim: {
                        periode: {
                          month: moment()
                            .locale('id')
                            .format('MMMM'),
                          year: moment()
                            .locale('id')
                            .format('YYYY')
                        },
                        status: claimStatus,
                        description: ''
                      },
                      staff: this.$store.getters.userLoggedIn.id
                    }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.getAllSubscription(false)
                      this.onClickFooter(1, item.id, item.id_company, true)
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat menyambung ke server'
          })
        }
      } else this.onClickFooter(1, item.id, item.id_company, item.claimStatus == 'approved' ? false : true)
    },
    async downloadCertificate(data) {
      try {
        this.isCertificateLoading = true

        await this.generateClaimCertificate({
          id_subscription: data.id,
          company: data.companyName,
          member: data.memberName,
          staff: this.$store.getters.userLoggedIn.name
        })
        this.isCertificateLoading = false
      } catch (error) {
        this.isCertificateLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    onClickFooter(index, id_subscription, id_company, isSave) {
      this.selectedSubscription = {
        id_subscription,
        id_company,
        isSave
      }

      this.changeForm(index)

      this.dialog = true
    },
    togglePropsExpanded(props) {
      this.$set(props, 'expanded', !props.expanded)
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllSubscription()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}
.autocomplete {
  max-width: 14%;
  margin-right: 2%;
}
.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}

.list-item {
  color: white;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.cardtext {
  padding-top: 28px;
  text-align: start;
}
</style>
